import Header from "./Header";
import Footer from "./Footer";
import Nabar from "./Navbar";
import Home from "./pages/Home";
import Ustawienia from "./pages/Ustawienia";
import Faq from "./pages/Faq";
import { Route, Routes } from "react-router-dom";

function App() {
  return (
    <>
      <Header />

      <main className="">
        <div className="container mx-auto px-4">
          <div className="flex flex-col md:flex-row md:flex-wrap gap-4">
            <div className="grow">
              <div className="prose lg:prose-xl prose-stone">
                <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="/konfiguracja" element={<Ustawienia />} />
                  <Route path="/faq" element={<Faq />} />
                </Routes>
              </div>
            </div>
            <Nabar />
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
}

export default App;
