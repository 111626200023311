import naglowek from "./i/naglowek.png";
import ButtonReadEmail from "./ButtonReaqdEmail";

function Header() {
  return (
    <header className="naglowek">
      <div className="container mx-auto px-4 flex flex-wrap flex-col items-center md:flex-row md:justify-between gap-8">
        <a href="/" className="naglowek__logo">
          <img src={naglowek} alt="Logo 4lomza.pl" weight="354" height="57" className="h-4 md:h-8" />
          <span>Poczta</span>
        </a>
        <ButtonReadEmail />
      </div>
    </header>
  );
}

export default Header;
