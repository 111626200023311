function Ustawienia() {
  return (
    <>
      <h1>Konfiguracja poczty e-mail</h1>

      <ul>
        <li>
          Serwer poczty wychodzącej (smtp): <strong>poczta.hi.pl</strong>
        </li>
        <li>
          Serwer poczty przychodzącej (pop3): <strong>poczta.hi.pl</strong>
        </li>
        <li>
          Login: pełen email (np. <strong>hermenegilda@hi.pl</strong>)
        </li>
      </ul>

      <h3>dla POP3:</h3>
      <ul>
        <li>zaznaczyć: "Ten serwer wymaga zaszyfrowanego połączenia (SSL)</li>
        <li>
          port: <strong>995</strong>
        </li>
      </ul>

      <h3>dla SMTP:</h3>
      <ul>
        <li>Użyj połączenia szyfrowanego typu SSL/TLS</li>
        <li>
          port: <strong>465</strong>
        </li>
        <li>zaznaczyć opcję "Serwer wymaga uwierzytelnienia"</li>
      </ul>

      <h3>dla IMAP:</h3>
      <ul>
        <li>Rodzaj zabezpieczenia: SSL</li>
        <li>port: 993</li>
      </ul>
    </>
  );
}

export default Ustawienia;
