import { Link, useMatch, useResolvedPath } from "react-router-dom";

function Nabar() {
  const path = window.location.pathname;
  return (
    <aside className="mt-16 md:mt-0 w-64" aria-label="Sidebar">
      <div className="">
        <Link to="/" className="block mb-4 font-bold">
          Strona główna
        </Link>

        <nav className="nav">
          <ul className="space-y-2">
            <CustomLink to="/konfiguracja">Konfiguracja programu pocztowego</CustomLink>
            <a href="https://poczta.hi.pl/postadm/users/login.php">Zarządanie kontem</a>
            <a href="https://poczta.hi.pl/postadm/login.php">Sekcja administratorów</a>
            <CustomLink to="/faq">Rozwiązanie problemów (FAQ)</CustomLink>
          </ul>
        </nav>
      </div>
    </aside>
  );
}

function CustomLink({ to, children, ...props }) {
  const resolvedPath = useResolvedPath(to);
  const isActive = useMatch({ path: resolvedPath.pathname, end: true });
  return (
    <li className={isActive ? "active" : ""}>
      <Link to={to} {...props}>
        {children}
      </Link>
    </li>
  );
}

export default Nabar;
