function Home() {
  return (
    <>
      <p>Niniejsza strona jest chroniona certyfikatem SSL.</p>

      <p>
        <a href="/ustawienia">Konfiguracja programu pocztowego</a> - ustawienia opcji w programie pocztowym.
      </p>

      <p>
        <a href="https://poczta.hi.pl/postadm/users/login.php">Zarządzanie kontem pocztowym</a> - tu zmienisz hasło do skrzynki e-mail, ustawisz przekierowanie.
      </p>

      <p>
        <a href="https://ssl.4lomza.pl/poczta/">Bramka pocztowa</a> służy do odbierania e-maili poprzez www. Z usługi tej może korzystać każdy, kto posiada
        konto pocztowe na serwerach <a href="http://speed.hi.pl/">SPEED s.c.</a>
      </p>

      <p>
        Jeśli jesteś zainteresowany posiadaniem konta skontaktuj się z nami e-mail: <a href="mailto:speed@4lomza.pl">speed@4lomza.pl</a>.
      </p>
    </>
  );
}

export default Home;
