function Footer() {
  return (
    <footer className="stopka">
      <div className="container mx-auto px-4 flex justify-between">
        <div>
          Kontakt z nami: <a href="mailto:">speed@4lomza.pl</a>
        </div>
        <div>
          WWW: <a href="http://speed.hi.pl">speed.hi.pl</a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
