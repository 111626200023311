function Faq() {
  return (
    <>
      <h1>FAQ</h1>
      <p>Najczęściej zadawane pytania</p>

      <div className="toc">
        <ul>
          <li>
            <a href="#bladserwera">Komunikat "Błąd serwera..."</a>
          </li>
        </ul>
      </div>

      <h3 id="bladserwera">Komunikat "Błąd serwera..."</h3>

      <p>
        Jeżeli podczas usuwania wiadomości pojawi się komunikat <code>„Błąd serwera”</code> możemy go naprawić ustawiając na bramce pocztowej automatyczne
        usuwanie e-maili, a nie przenoszenie ich do kosza
      </p>

      <p>Robimy to w następujący sposób:</p>
      <p>
        <code>Ustawienia &rarr; Foldery specjalne</code>
        Wybrać w polu <code>"Kosz"</code> wartość <code>"---"</code>
      </p>
      <p>Zapisujemy konfigurację.</p>

      <a href="/i/kosz.png">
        <img src="/i/kosz.png" alt="kosz.png" />
      </a>
    </>
  );
}

export default Faq;
